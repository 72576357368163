import React, { useContext, useEffect, useRef, useState } from 'react';
import dragDropImage from './drag-drop.png';
import { ServiceAPI } from '../../../Api/ServiceAPI';
import { ServiceInfo, UserContext } from '../../../Utilis';
import { ReactComponent as ConfirmIcon } from "../../../Png/check-solid.svg"
const DragAndDrop = ({ appName }) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploaded,setUploaded]=useState(false)
    const [showConfUploBtn,setShowConfUploBtn]=useState(true)
    const [progressByFile,setFileProgressByFile]=useState({})
    const [fileTypeError, setFileTypeError] = useState('');
    const [dragOver, setDragOver] = useState(false);
    const {user,setUser} = useContext(UserContext);
    const controller = useRef(null);
    const [cameraVideoFile,setCameraVideoFile]=useState(null);
    const [showMailParagraph, setShowMailParagraph] = useState(false);
    const [serviceName, setServiceName] = useState("");
    const stripFileName=(name)=>{
        let ret_name=name
        ret_name=ret_name.replace("#","")
        return ret_name
    }

    
    const handleUpload=async()=>{
        let _accessToken=""
        setUploadProgress(0)
        setUploaded(false);
        setFileTypeError("")
        controller.current=new AbortController();
        try{
            const resLogin = await ServiceAPI.login();
            if (resLogin.data.result === 0) {
                _accessToken = resLogin.data.auth;
            } else {
                throw new Error("Failed to login");
            }
            const { sqlNameId: service, serviceNumber } = ServiceInfo[appName];
            if (uploadedFiles.length === 0) return;
            if (!service || serviceNumber < 0) return;
            if (serviceName === "") {
                setFileTypeError("Folder name is empty");
                return;
        }
        const folderResponse = await ServiceAPI.createFolder(_accessToken, user[service], stripFileName(serviceName));
        if (folderResponse.data.result !== 0) throw new Error("Failed to create folder");
        const folderNumber = folderResponse.data.metadata.folderid;
        let data = {
            folderName: serviceName,
            folderId: folderNumber,
            serviceNumber,
            karmenId: user.Id,
        };
        const serviceResponse = await ServiceAPI.createService(data);
        if (!serviceResponse) throw new Error("Failed to create service");
        data = { ...data, ...serviceResponse.data };
        setShowConfUploBtn(false);
        // Upload all files
        const uploadResponses = await Promise.all(
            uploadedFiles.map((file, index) =>
                ServiceAPI.upload_file(_accessToken, serviceResponse.data.folderId, file, setFileProgressByFile, index, controller.current.signal)
            )
        );
        const acceptedTypes = [
            "video/mp4", "video/avi", "video/quicktime", "video/webm",
            "application/zip", "application/x-zip-compressed", "application/x-rar-compressed"
        ];
        uploadedFiles.forEach((file, index) => {
            setUploadedFiles((prev) => ({ ...prev, [index.toString()]: 100 }));
            if (acceptedTypes.includes(file.type)) {
                const matchedUpload = uploadResponses.find(
                    (upload) => upload.data.metadata[0].name === file.name
                );
                if (matchedUpload) {
                    data.videoId = matchedUpload.data.metadata[0].fileid;
                    data.videoName = file.name;
                }
            }
        });
        await ServiceAPI.createVideoUpload(data);
        setUploaded(true);
        setUploadProgress(0);
        setShowConfUploBtn(true);
        setUploadedFiles([]);
        setFileTypeError("");
        setFileProgressByFile({})
        setServiceName("")
        } catch (error) {
        console.error(error);
        setFileTypeError(error.response?.data?.message || "Something went wrong. Try again");
        setUploaded(true);
        setUploadProgress(0);
        setShowConfUploBtn(true);
        setUploadedFiles([]);
        setFileProgressByFile({})
        setServiceName("")
    }
    }

    const handleDrop = (event) => {
        event.preventDefault();
        setDragOver(true);
        
        const files = event.dataTransfer.files;
        const acceptedTypes = ['video/mp4', 'video/avi', 'video/quicktime', 'video/webm','application/zip', 'application/x-zip-compressed','application/x-rar-compressed'];
        const newFiles = [];
        if(user)
        for (let i = 0; i < files.length; i++) {
            if (acceptedTypes.includes(files[i].type)&&!uploadedFiles.find((element)=>element.name==files[i].name)) {
                newFiles.push(files[i]);
            } else {
                setFileTypeError(`${files[i].name} is not a supported file type.`);
            }
        }
        setUploadedFiles([...uploadedFiles, ...newFiles]);
    };
    const handleFileSelect=(event)=>{
        event.preventDefault();
        const files = event.target.files
        const acceptedTypes = ['video/mp4', 'video/avi', 'video/quicktime','video/webm','application/zip', 'application/x-zip-compressed','application/x-rar-compressed'];
        const newFiles = [];
        if(user)
        for (let i = 0; i < files.length; i++) {
            if (acceptedTypes.includes(files[i].type)&&!uploadedFiles.find((element)=>element.name==files[i].name)) {
                newFiles.push(files[i]);
            } else {
                setFileTypeError(`${files[i].name} is not a supported file type.`);
            }
        }
        setUploadedFiles([...uploadedFiles, ...newFiles]);
    }
    const handleButtonClick = () => {
        document.getElementById('fileInput').click();
      };
    const handleDragOver = (event) => {
        event.preventDefault();
        setDragOver(true);
        event.dataTransfer.dropEffect = 'copy';
    };
    const handleClearUploadedFiles = () => {
        setUploadedFiles([]);
        setFileTypeError('');
        setUploaded(false)
        setShowConfUploBtn(true)
        setUploadProgress(0)
        setFileProgressByFile({})
        if(controller.current)
            if (!controller.current.signal.aborted) {
                controller.current.abort();
            }
    };
    const handleConfirmUpload = (file) => {
        
        handleUpload();
    };
    const download_instr=(filename)=>{
        setShowMailParagraph(true);
        const link = document.createElement('a');
        link.href = require("./../../../Png/"+filename);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const handleDownloadInstructions = () => {
        try {
            
            switch(appName){
                case ServiceInfo.Biofouling.Name:
                    download_instr(ServiceInfo.Biofouling.instructionsPath)
                    break
                case ServiceInfo.TableApp.Name:
                    download_instr(ServiceInfo.TableApp.instructionsPath)
                    break
                case ServiceInfo.Custom.Name:
                    download_instr(ServiceInfo.Custom.instructionsPath)
                    break
                case ServiceInfo.SalmorApp.Name:
                    download_instr(ServiceInfo.SalmorApp.instructionsPath)
                    break
                case ServiceInfo.BiomassApp.Name:
                    download_instr(ServiceInfo.BiomassApp.instructionsPath)
                    break
                case ServiceInfo.Mortality.Name:
                    download_instr(ServiceInfo.Mortality.instructionsPath)
                    break
                case ServiceInfo.Waste.Name:
                    download_instr(ServiceInfo.Waste.instructionsPath)
                    break
                case ServiceInfo.Inspection.Name:
                    download_instr(ServiceInfo.Inspection.instructionsPath)
                    break
                case ServiceInfo.Cagedetection.Name:
                    download_instr(ServiceInfo.Cagedetection.instructionsPath)
                    break
                case ServiceInfo.FishCounting.Name:
                    download_instr(ServiceInfo.FishCounting.instructionsPath)
                    break
                default:
                    download_instr(ServiceInfo.TableApp.instructionsPath)
                    break
            }
        } catch (error) {
            console.error('Error generating Word document:', error);
        }
    };
    const handleFileRemove=(index)=>{
        const newFiles=uploadedFiles.filter((files,i)=>i!==index)
        setUploadedFiles([...newFiles])
    }
    useEffect(()=>{
        let count=0;
        let totalPersernt=0;
        Object.entries(progressByFile).forEach((entry)=>{
            console.log((entry))
            if(entry[1]==-1)
                return
            totalPersernt+=entry[1]
            count+=1
        })
        const progress=Number(totalPersernt/count)
        setUploadProgress(progress)
       
    },[progressByFile])
    useEffect(()=>{
        if(cameraVideoFile)
            setUploadedFiles([...uploadedFiles,cameraVideoFile])
    },[cameraVideoFile])
    return (
        <div className={`container ${dragOver ? 'drag-over' : ''}`}>
            <h1>{ServiceInfo[appName].displayName}</h1>
            <button className="download-button" onClick={handleDownloadInstructions}>Download Instructions</button>
            {showMailParagraph &&appName==ServiceInfo.Biofouling.Name?(
                <b><p>Send us an e-mail (contact@karmenstudio.ai) with data requested on instruction document above</p></b>
            ):null}
            <div className="drag-drop-container" onDrop={handleDrop} onDragOver={handleDragOver}>
                <div className="drag-drop-area">
                    <img src={dragDropImage} alt="Drag and drop" className="drag-drop-image" />
                    <p><b>Drag and drop files here</b></p>
                    <p className="drag-drop-text"><b>or</b></p>
                    <div>
                        <button className='fileselect-btn' onClick={handleButtonClick}>Select files</button>
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            accept="'video/mp4', 'video/avi', 'video/quicktime', 'video/webm','application/zip', 'application/x-zip-compressed','application/x-rar-compressed'"
                            multiple
                            onChange={handleFileSelect}
                        />
                    </div>
                </div>
            </div>
            {uploadedFiles.length>0 ?<>
            <div style={{ margin:"5px",backgroundColor:"#DAC625", padding:"10px",borderRadius:"10px"}} className="uploaded-files">
                <p style={{fontSize:"20px"}}>Selected files</p>
                <div>
                            <label style={{ fontWeight: "700", marginRight: "5px", cursor: "default" }}>Folder name:</label>
                            <input style={{ width: "40%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "20px" }} type='text' value={serviceName} onChange={(event) => setServiceName(event.target.value)} />
                        </div>
                 {uploadedFiles.map((file, index) => (
                    <div key={index} style={{display:"flex",flexDirection:"row",alignItems:"center"}}><p style={{fontWeight:"700",marginRight:"5px",cursor:"default"}}>Name: </p><p style={{fontSize:"16px",marginRight:"5px",cursor:"default"}}> {file.name}</p>{progressByFile.hasOwnProperty(index)?<span style={{fontWeight:"700"}}><img style={{paddingLeft:"5px"}} width={"15px"} src={require("../../../Png/upload.webp")}/> {progressByFile[index]==-1?"Already exists":`${progressByFile[index]} %`}</span>:<img style={{cursor:"pointer"}} src={require("../../../Png/delete.webp")} height={"16px"} onClick={()=>handleFileRemove(index)}/>}</div>
                ))}
            </div> 
            {showConfUploBtn?<button className='confirm' onClick={handleUpload}>Upload selected files</button>:null}
            {/* <button className='clear' onClick={handleClearUploadedFiles}>Clear selected files</button> */}
            </>:null}
                <div className="upload-progress-container">
                {uploadProgress > 0||!showConfUploBtn ?<> <progress value={uploadProgress} max="100"></progress>
                    <span>{uploadProgress.toFixed(2)}%</span></>:null}
                    {uploaded?<><p className="upload-done-info">Upload completed<ConfirmIcon className="icon" /></p>

                    {ServiceInfo[appName].hasOwnProperty("after_download_info")&&ServiceInfo[appName].after_download_info.map((s,index)=>
                        {return <p key={index} style={{color:"#354a4b"}}>{s}</p>}
                )}
                </>:null}
                </div>            
            <div>
            {fileTypeError && <p className="error">{fileTypeError}</p>}
            </div>
            {/* {appName===ServiceInfo.TableApp.Name?<Camera setVideoFile={(value)=>setCameraVideoFile(value)} />:null} */}
        </div>
    );
};
export default DragAndDrop;
