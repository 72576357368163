import React, { useContext, useEffect, useRef, useState } from 'react';
import dragDropImage from '../drag-drop.png';
import { ServiceAPI } from '../../../../Api/ServiceAPI';
import {  UserContext, ServiceInfo } from "../../../../Utilis";
import { ReactComponent as ConfirmIcon } from "../../../../Png/check-solid.svg";

const CustomDragDrop = ({ appName }) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploaded, setUploaded] = useState(false);
    const [showConfUploBtn, setShowConfUploBtn] = useState(true);
    const [progressByFile, setFileProgressByFile] = useState({});
    const [fileTypeError, setFileTypeError] = useState('');
    const [dragOver, setDragOver] = useState(false);
    const { user } = useContext(UserContext);
    const controller = useRef(null);
    const [showMailParagraph, setShowMailParagraph] = useState(false);
    const [serviceName, setServiceName] = useState("");

    const handleUpload = async () => {
        let _accessToken = "";
        setUploadProgress(0);
        setUploaded(false);
        controller.current = new AbortController();
        // login
        try {
            const resLogin = await ServiceAPI.login();
            if (resLogin.data.result === 0) {
                _accessToken = resLogin.data.auth;
            }
        } catch (ex) {
            console.log(ex);
            return;
        }
        // get the correct service folderId from user
        const service = ServiceInfo[appName].sqlNameId;
        const serviceNumber = ServiceInfo[appName].serviceNumber;
        if (!uploadedFiles.length) return;
        if (serviceName === "") {
            setFileTypeError(`Folder name is empty`);
            return;
        }
        let data = {};
        ServiceAPI.createFolder(_accessToken, user[service], serviceName).then(resFolder => {
            if (resFolder && resFolder.data.result === 0) {
                const folderNumber = resFolder.data.metadata.folderid;
                data = { ...data, folderName: serviceName, folderId: folderNumber, serviceNumber, karmenId: user.Id };
                return ServiceAPI.createService(data);
            }
        }).then(resService => {
            if (resService) {
                data = { ...data, ...resService.data };
                setShowConfUploBtn(false);
                Promise.all(uploadedFiles.map((file, index) =>
                    ServiceAPI.upload_file(_accessToken, resService.data.folderId, file, setFileProgressByFile, index, controller.current.signal)
                )).then(resUpload => {
                    if (resUpload) {
                        let idFile = null;
                        let fName = null;
                        const acceptedTypes = ['video/mp4', 'video/avi', 'video/quicktime', 'video/webm','application/zip', 'application/x-zip-compressed','application/x-rar-compressed'];
                        uploadedFiles.forEach(file => {
                            if (acceptedTypes.includes(file.type))
                                fName = file.name;
                        });
                        if (fName) {
                            resUpload.forEach(dat => {
                                idFile = dat.data.metadata[0].fileid;
                                if (dat.data.metadata[0].name === fName)
                                    data = { ...data, videoId: idFile, videoName: fName };
                            });
                        } else
                            data = { ...data, videoId: resUpload[0].data.metadata[0].fileid, videoName: resUpload[0].data.metadata[0].name };
                        return ServiceAPI.createVideoUpload(data);
                    }
                }).then(resVideoUpload => {
                    // handle video upload response
                }).catch(error => {
                    console.log(error);
                });
            }
        }).catch(error => {
            console.log(error);
            if (error.response.data.message === "Already exists")
                setFileTypeError(`Folder name already exists`);
        });
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragOver(true);

        const files = event.dataTransfer.files;
        const newFiles = [];
        if (user) {
            for (let i = 0; i < files.length; i++) {
                if (!uploadedFiles.find(element => element.name === files[i].name)) {
                    newFiles.push(files[i]);
                }
            }
        }
        setUploadedFiles([...uploadedFiles, ...newFiles]);
    };

    const handleFileSelect = (event) => {
        event.preventDefault();
        const files = event.target.files;
        const newFiles = [];
        if (user) {
            for (let i = 0; i < files.length; i++) {
                if (!uploadedFiles.find(element => element.name === files[i].name)) {
                    newFiles.push(files[i]);
                }
            }
        }
        setUploadedFiles([...uploadedFiles, ...newFiles]);
    };

    const handleButtonClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragOver(true);
        event.dataTransfer.dropEffect = 'copy';
    };

    const handleClearUploadedFiles = () => {
        setUploadedFiles([]);
        setFileTypeError('');
        setUploaded(false);
        setShowConfUploBtn(true);
        setUploadProgress(0);
        setFileProgressByFile({});
        if (controller.current && !controller.current.signal.aborted) {
            controller.current.abort();
        }
    };

    const handleDownloadInstructions = () => {
        setShowMailParagraph(true);
        const link = document.createElement('a');
        link.href = require("./../../../../Png/" + ServiceInfo.Custom.instructionsPath);
        link.download = ServiceInfo.Custom.instructionsPath;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFileRemove = (index) => {
        const newFiles = uploadedFiles.filter((_, i) => i !== index);
        setUploadedFiles([...newFiles]);
    };

    useEffect(() => {
        let count = 0;
        let totalPercent = 0;
        Object.entries(progressByFile).forEach(([, progress]) => {
            totalPercent += progress;
            count += 1;
        });
        const progress = Number(totalPercent / count);
        setUploadProgress(progress);
        if (progress >= 100 && Object.keys(progressByFile).length === uploadedFiles.length) {
            setUploaded(true);
            setUploadProgress(0);
            setShowConfUploBtn(true);
            setUploadedFiles([]);
        }
    }, [progressByFile, uploadedFiles.length]);

    return (
        <div className={`container ${dragOver ? 'drag-over' : ''}`}>
            <h1>{appName}</h1>
            <button className="download-button" onClick={handleDownloadInstructions}>Download Instructions</button>
            {showMailParagraph && (
                <b><p>Send us an e-mail (contact@karmenstudio.ai) with data requested on instruction document above</p></b>
            )}
            <div className="drag-drop-container" onDrop={handleDrop} onDragOver={handleDragOver}>
                <div className="drag-drop-area">
                    <img src={dragDropImage} alt="Drag and drop" className="drag-drop-image" />
                    <p>Drag and drop files here</p>
                    <p className="drag-drop-text">or</p>
                    <div>
                        <button className='fileselect-btn' onClick={handleButtonClick}>Select files</button>
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            multiple
                            onChange={handleFileSelect}
                        />
                    </div>
                </div>
            </div>
            {uploadedFiles.length > 0 && (
                <>
                    <div style={{ margin: "5px", backgroundColor: "#dcdcdc", padding: "10px", borderRadius: "10px" }} className="uploaded-files">
                        <p style={{ fontSize: "20px" }}>Selected files</p>
                        <div>
                            <label style={{ fontWeight: "700", marginRight: "5px", cursor: "default" }}>Folder name:</label>
                            <input style={{ width: "40%", padding: "10px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "20px" }} type='text' value={serviceName} onChange={(event) => setServiceName(event.target.value)} />
                        </div>
                        {uploadedFiles.map((file, index) => (
                            <div key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <p style={{ fontWeight: "700", marginRight: "5px", cursor: "default" }}>Name:</p>
                                <p style={{ fontSize: "16px", marginRight: "5px", cursor: "default" }}> {file.name}</p>
                                {progressByFile.hasOwnProperty(index)
                                    ? <span style={{ fontWeight: "700" }}><img style={{ paddingLeft: "5px" }} width={"15px"} src={require("../../../../Png/upload.webp")} alt="upload"/> {progressByFile[index]} %</span>
                                    : <img style={{ cursor: "pointer" }} src={require("../../../../Png/delete.webp")} height={"16px"} alt="delete" onClick={() => handleFileRemove(index)} />
                                }
                            </div>
                        ))}
                    </div>
                    {/* <button className='clear' onClick={handleClearUploadedFiles}>Clear</button> */}
                </>
            )}
            {uploadedFiles.length > 0 && showConfUploBtn && <button className='confirm' onClick={handleUpload}>Upload</button>}
            <div className="upload-progress-container">
                {(uploadProgress > 0 || !showConfUploBtn) && (
                    <>
                        <progress value={uploadProgress} max="100"></progress>
                        <span>{uploadProgress.toFixed(2)}%</span>
                    </>
                )}
                {uploaded && (
                    <>
                        <p className="upload-done-info">Upload completed<ConfirmIcon className="icon" /></p>
                        {ServiceInfo.Custom.hasOwnProperty("after_download_info") && ServiceInfo.Custom.after_download_info.map((info, index) => (
                            <p key={index} style={{ color: "#354a4b" }}>{info}</p>
                        ))}
                    </>
                )}
            </div>
            {fileTypeError && <p className="error">{fileTypeError}</p>}
        </div>
    );
};

export default CustomDragDrop;
