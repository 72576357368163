import React, { useEffect, useRef, useState } from 'react';
import './../Apps.css';
import Map from './Map';
import { SpaceAPI } from '../../../../Api/SpaceAPI';
import { useNavigate } from 'react-router-dom';

const Satellites = ({ name }) => {
    const [cages, setCages] = useState([]);
    const abortController = useRef(null)
    const navigator=useNavigate()
    useEffect(() => {
        if (abortController.current) abortController.current.abort();
        abortController.current = new AbortController();
        SpaceAPI.getLocations(abortController.current.signal).then((res) => {
            if (!res.data) setCages([])
            setCages(res.data)
        }).catch((error) => {
            console.error(error)
            setCages([])
        })
    }, [])

    return (
        <div className="file-upload-circles">
             <h1 className="header">Choose your location</h1>
             <div style={{display:"flex", gap:"20px", alignItems:"center",justifyContent:"center"
             }}>
            {cages.length>0?cages.map((cage,index)=>
            <div
            key={index}
            className="circle-wrapper"
            onClick={()=>navigator(`/home/satellites/${cage.Id}`)}
          >
            <div className="circle dashed-inner"></div>
            <div className="circle solid-middle"><p>{cage.name}</p></div>
            <div className="circle dashed-outer"></div>
        
            
          </div>
            ):null}
            </div>
        </div>
    );
};

export default Satellites;
